// Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto');

// Variables
@import 'variables';
@import '@splidejs/splide/css';
// Bootstrap
@import '~bootstrap/scss/bootstrap';




body{
    font-family: "Roboto" , sans-serif;
    font-weight: 400;
  font-display: swap;
}
.bold{
    font-weight: bold;
}
.navbar {
    box-shadow: 2px 2px 5px #000;
    background: #CD7430;
    
    color:#fff;
    
    
  }
  .left {
    float: left !important;
  }
  #navbarNav{
    
    width: 300px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 999;
   
    transition: all 0.3s;
    box-shadow: 12px 0 15px -4px rgba(0, 4, 8, 0.8), -12px 0 8px -4px rgba(0, 4, 8, 0.8);
  }


  .navbar .nav-item .nav-link {
    color: #fff;
  }

  .main-header {
    position: relative;
    background: url(../img/Sin-título-1.jpg);
    background-color: rgba(205, 116, 48,0.9);
    background-size: cover;
    max-width: 100%;
    
    top: 0;
    left: 0;
  }
    .gallery{
        background-size: cover !important;
        background: url(../img/Fondo-Gallery.png);
    }
    .gallery-sidepiece{
      background-color: rgba(205, 116, 48,0.8);
    }
  .background-overlay {

    background-color: rgba(205, 116, 48,0.8);

  }

  .form-control, .btn{
    border-radius: 0;
  }

  .team, footer {
    background: #577A55;
  }

.creation{
    background: url( "../img/2_PASO A PASO/BACKGROUND GVG_Mesa de trabajo 1.png");
}

.card-category{
    height: 100%;
    width: 100%;
    background-color: transparent;
    background-size: cover;
    margin:0;
    color: #fff;
    border:1px 0 0 0 solid #fff;
}
.card-category:hover{
    background-color: rgba(28, 140, 120, 0.6);;
}
.card-menu{
    height: 100%;
    width: 100%;
    background-color: transparent;
    background-size: cover;
    margin:0;
}
.card-navbar{
    height: 100%;
    width: 100%;
    background-color: #CD7430;opacity:1;
    background-size: cover;
    margin:0;
}
.row{
    --bs-gutter-x:0;
}

h2{
    font-size: 2rem;
    opacity:1;
}
h3{
    font-size: 1.5rem;

    opacity:1;
}


.ourange{
    height: 100%;
    color: #fff;
    background-color: rgba(205, 116, 48,0.8);
    background-size: cover;
    margin:0;
    --bs-gutter-x:0;
}

.carous-1{
    background: url( ../img/3_NOSOTROS/img-1.png);
    background-size: cover;
    background-position: center;
}
.carous-2{
    background: url( ../img/3_NOSOTROS/img-2.jpg);
    background-size: cover;
    background-position: center;
}
.carous-3{
    background: url( ../img/3_NOSOTROS/img-4.jpg);
    background-size: cover;
    background-position: center;

}
.carous-4{
    background: url( ../img/3_NOSOTROS/img-3.jpg);
    background-size: cover;
    background-position: center;
}
.carous-5{
    background: url( ../img/3_NOSOTROS/img-5.jpg);
    background-size: cover;
    background-position: center;
}
    .carousel .carousel-inner{
       
    }
    .carousel-inner .carousel-item {
    min-height:200px;
    //prevent it from stretch in screen size < than 768px
    object-fit:cover
    }

    @media(max-width:768px){
    .carousel .carousel-inner{
    //prevent it from adding a white space between carousel and container elements
    height:auto
     }

    }

    .accordion-button:not(.collapsed) {
        color: #70946E;
        background-color: #e7f1ff;
        box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
      }

      #kontakt{
          background-color: #fff;

      }
      
    #libraryback{
    background:  url( ../img/6_FORMULARIODE_CONTACTO/IMG-20190305-WA0000-2.jpg);

    background-origin: border-box;
    background-size: auto;
    background-position: left;
    background-repeat: no-repeat;
}
.fixed{
position:fixed;
}


#Reviews{

  background-color: #E5E5E5;
}
.reviews{

  background-color: #E5E5E5;
}



  #btn-back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    }

    #logo-footer{
        width:50%;
    }
 .card-category-gallery{
            height: 100%;
            width: 100%;
            background-color: rgba(205, 116, 48,0.8);
            background-size: cover;
            margin:0;
            color: #fff;
            border-width: 0px 1px 0px 0px;
            border-style: solid;
            border-color: white;
        }
        .card-category{
          height: 100%;
          width: 100%;
         
          margin:0;
          color: #fff;
          border-width: 0px 1px 0px 0px;
          border-style: solid;
          border-color: white;
      }
        .card-category-gallery:hover{
          background-color: rgba(205, 116, 48,0.8);
            background-color: rgb(112, 148, 110,0.8);
        }
    @media (max-width: 1081px) {

        .footer-button{
          height:40px;
        }

        
        .menu-hide{
            display:none;
        }
        
        #logo-footer{
            width:50%;
        }
        .card-category{
            height: 100%;
            width: 100%;
            background-color: transparent;
            background-size: cover;
            margin:0;
            color: #fff;
            border-width: 1px;
            border-style: solid;
            border-color: white;
        }
        
        .card-category-gallery{
          height: 100%;
          width: 100%;
          background-color: transparent;
          background-size: cover;
          margin:0;
          color: #fff;
          border-width: 1px;
          border-style: solid;
          border-color: white;
      }
    
     
        .card-category-gallery-active{
            height: 100%;
            width: 100%;
            background-color: rgb(112, 148, 110,0.8);
            background-size: cover;
            margin:0;
            color: #fff;
        }
        .card-category-gallery:hover{
          background-color: rgb(112, 148, 110,0.8);
        }
        .modal-content
        {
            height:50%;
            width:50%;
        }
        
    }
    @media (min-width: 900px) {
       #splide-xs{
      display:none;
        }
      }
      @media (max-width: 901px) {
        #splide-lg{
      display:none;
      }
      }
  
    @media (min-width: 1081px) {
        
        .navbar{
            display:none;
        }
        .footer-button{
          height:50px;
        }

        #logo-footer{
            width:15%;
        }
        #tel{
            display:none;
        }
        .card-category-gallery{
          height: 100%;
          width: 100%;
          background-color: transparent;
          background-size: cover;
          margin:0;
          color: #fff;
          border-width: 0px 1px 0px 0px;
          border-style: solid;
          border-color: white;
      }
    
     
        .card-category-gallery-active{
            height: 100%;
            width: 100%;
            background-color: rgb(112, 148, 110,0.9);
            background-size: cover;
            border-width: 0px 1px 0px 0px;
            margin:0;
            color: #fff;
        }
        .card-category-gallery:hover{
          background-color: rgb(112, 148, 110,0.9);
        }
       
    }



    // Gallery css
    .products{
        background: #ECECEC;
        background-color: #ECECEC;
    }
    $mynewcolor:#CD7430;

    .btn-primary { 
        color: #ffffff; 
        background-color: #CD7430; 
        border-color: #CD7430; 
        border-radius: 15px;
      } 
       
      .btn-primary:hover, 
      .btn-primary:focus, 
      .btn-primary:active, 
      .btn-primary.active, 
      .open .dropdown-toggle.btn-primary { 
        color: #ffffff; 
        background-color: #D38243; 
        border-color: #CD7430; 
      } 
       
      .btn-primary:active, 
      .btn-primary.active, 
      .open .dropdown-toggle.btn-primary { 
        background-image: none; 
      } 
       
      .btn-primary.disabled, 
      .btn-primary[disabled], 
      fieldset[disabled] .btn-primary, 
      .btn-primary.disabled:hover, 
      .btn-primary[disabled]:hover, 
      fieldset[disabled] .btn-primary:hover, 
      .btn-primary.disabled:focus, 
      .btn-primary[disabled]:focus, 
      fieldset[disabled] .btn-primary:focus, 
      .btn-primary.disabled:active, 
      .btn-primary[disabled]:active, 
      fieldset[disabled] .btn-primary:active, 
      .btn-primary.disabled.active, 
      .btn-primary[disabled].active, 
      fieldset[disabled] .btn-primary.active { 
        background-color: #CD7430; 
        border-color: #CD7430; 
      } 
       
      .btn-primary .badge { 
        color: #CD7430; 
        background-color: #ffffff; 
      }
      .btn-selected { 
        color: #70946E; 
        background-color: #FFFFFF; 
        border-color: #FFFFFF; 
        border-radius: 15px;;
        
      } 
       
      .btn-selected:hover, 
      .btn-selected:focus, 
      .btn-selected:active, 
      .btn-selected.active, 
      .open .dropdown-toggle.btn-selected { 
        color: #70946E; 
        background-color: #FFFFFF; 
        border-color: #FFFFFF; 
      } 
       
      .btn-selected:active, 
      .btn-selected.active, 
      .open .dropdown-toggle.btn-selected { 
        background-image: none; 
      } 
       
      .btn-selected.disabled, 
      .btn-selected[disabled], 
      fieldset[disabled] .btn-selected, 
      .btn-selected.disabled:hover, 
      .btn-selected[disabled]:hover, 
      fieldset[disabled] .btn-selected:hover, 
      .btn-selected.disabled:focus, 
      .btn-selected[disabled]:focus, 
      fieldset[disabled] .btn-selected:focus, 
      .btn-selected.disabled:active, 
      .btn-selected[disabled]:active, 
      fieldset[disabled] .btn-selected:active, 
      .btn-selected.disabled.active, 
      .btn-selected[disabled].active, 
      fieldset[disabled] .btn-selected.active { 
        background-color: #FFFFFF; 
        border-color: #FFFFFF; 
      } 
       
      .btn-selected .badge { 
        color: #FFFFFF; 
        background-color: #70946E; 
      }
      .btn-info { 
        color: #CD7430; 
        background-color: #FFFFFF; 
        border-color: #FFFFFF; 
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8.66208px;
        border-radius: 15px;
      } 
       
      .btn-info:hover, 
      .btn-info:focus, 
      .btn-info:active, 
      .btn-info.active, 
      .open .dropdown-toggle.btn-info { 
        color: #CD7430; 
        background-color: #FFFFFF; 
        border-color: #FFFFFF; 
      } 
       
      .btn-info:active, 
      .btn-info.active, 
      .open .dropdown-toggle.btn-info { 
        background-image: none; 
      } 
       
      .btn-info.disabled, 
      .btn-info[disabled], 
      fieldset[disabled] .btn-info, 
      .btn-info.disabled:hover, 
      .btn-info[disabled]:hover, 
      fieldset[disabled] .btn-info:hover, 
      .btn-info.disabled:focus, 
      .btn-info[disabled]:focus, 
      fieldset[disabled] .btn-info:focus, 
      .btn-info.disabled:active, 
      .btn-info[disabled]:active, 
      fieldset[disabled] .btn-info:active, 
      .btn-info.disabled.active, 
      .btn-info[disabled].active, 
      fieldset[disabled] .btn-info.active { 
        background-color: #FFFFFF; 
        border-color: #FFFFFF; 
      } 
       
      .btn-info .badge { 
        color: #FFFFFF; 
        background-color: #CD7430; 
      }

      .subcategory-row{

        background-color: #70946E;

      }
      .modal-dialog
        {
            min-height:100%;
            min-width:100%;
            justify-content: center;
        }
        .image-container {
            display: flex;
            justify-content: center;
            
          }
          #Open-Form-Review{
            font-size:large;
          }
       
        // .pop {
        // overflow: hidden;
       
        // height: 250px;
        // }
        // .pop img {
        
        // height: 250px;
        // position:relative;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        // }
        .pop {
            min-width: 100%;
            height: 282px;
            overflow: hidden;
        }
        .pop span.img {
            background-position: center;
            background-size: cover;
            height: 282px;
            display: block;
        }
        
          .close{
            position:absolute;
            top:5px;
            right:5px;
            
          }



          .photo{
            
          }
          
          .photo img{
            object-fit: cover;
            
            box-shadow: 10px 15px 25px 0 rgba(0, 0, 0, 0.2);
            
            transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
            margin-top: -10px;
          }
          
          .photo:hover img{
            box-shadow: 1px 1px 10px 0 rgba(0,0,0,.1);
          }
          
          .photo .glow-wrap{
            overflow: hidden;
            position: relative;
            width: 100%;
            height: 100%;
            top: 0;
            margin-top: -10px;
          }
          
          .photo .glow{
            display: block;
           
            background: rgba(255,255,255,.2);
            top: 0;
            filter: blur(5px);
            transform: rotate(45deg) translate(-450%, 0);
            transition: all .5s cubic-bezier(0.645, 0.045, 0.355, 1);
          }
          
          .photo:hover .glow{
            transform: rotate(45deg) translate(450%, 0);
            transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
          }
          
          .photo:hover img,
          .photo:hover .glow-wrap{
            margin-top: 0;
          }

          .contact-btn{
            margin-top: 220px !important;
          }

          .green{
            background-color: rgb(112, 148, 110,0.9);

          }
          .pattern{
            background: url(../img/patterns_geometric-03.png);
            background-color: rgb(112, 148, 110,0.9);
            background-size: auto;
            color: #fff;
            position: absolute;
            float: left;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          #partnerButton{
            border-radius: 0px;
            border-color: #185343;
            border-width: 3px;
          }
          .partner-form{
            background-color: rgb(112, 148, 110,0.9);
            color: #FFF;
            border-color: #185343;
            border-width: 3px;
            font-weight: normal;
          }
          .partner-form, select, textarea{
            color: #FFF;
            font-weight: normal;
          }
          .partner-form:focus {
            background-color: rgb(112, 148, 110,0.9);
            color: #FFF;
            border-color: #185343;
            border-width: 3px;
            font-weight: normal;
          }
        
        .partner-form::placeholder{
          color: #FFF;
          font-weight: normal;
        }
        .modal-content-1{
          position: absolute;
          float: left;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        .modal-content-2{
          position: absolute;
          float: left;
          left: 50%;
          
          transform: translate(-50%);
        }
        
        @media (max-width: 1080px) {
          .modal-content{
            height: 55%;
            width: 75%;
          }
        }
        @media (min-width: 1081px) {
            .averga{
              transition: all 0.6s ease;
              -webkit-transition: all 0.6s ease;
              border-radius: 100%;
              min-height:23vh;
              -webkit-transition-delay: 0.32s;
                      transition-delay: 0.32s;
              background: #CD7430;
              animation-fill-mode:forwards;

            }
            .creation-title-inner{
              display:none;
            }
            .averga:hover{
              border-radius: 0;
              box-shadow: 0px 8px 6px -4px #000000;
              background: #CD7430;
              transition: all 0.5s ease-in-out;
              -webkit-transition: all 0.5s 0s 1 ease-in-out 1s normal forwards;
              
              -webkit-transition-delay: 0.32s;
                      transition-delay: 0.32s;
                      animation-fill-mode:forwards;
                      -webkit-transform: translateY(-15px) scale(1);
                      transform: translateY(-15px) scale(1);
            }
         }
          @media (max-width: 1080px) {
            .averga{
              border-radius: 0;
              box-shadow: 0px 8px 6px -4px #000000;
              background: #CD7430;
              transition: all 0.5s ease-in-out;
              -webkit-transition: all 0.5s 0s 1 ease-in-out 1s normal forwards;
              
              -webkit-transition-delay: 0.2s;
                      transition-delay: 0.2s;
                      animation-fill-mode:forwards;
                      -webkit-transform: translateY(-15px) scale(1);
                      transform: translateY(-15px) scale(1);
            }
            .averga img{
              width:75%;
            }
            
          }

          .white{
            color: #fff;
          }
          video::-webkit-media-controls-overlay-play-button {
            display: none;
          }
          @media (max-width: 899px) {
            .modal-try{
              height:80%;
              width:85%;
            }
          }
          @media (min-width: 899px) {
            .modal-try{
              height:65%;
              width:75%;
            }

          }
          @media (min-width: 1281px) {
            .modal-try{
              height:50%;
              width:75%;
            }

          }
          @media (min-width: 1100) {
            .card-responsive{
            margin-bottom: 5.2rem !important;
            margin-top: 5.2rem !important;
          }
        }
          @media (min-width: 1440px) {
            .card-responsive{
            margin-bottom: 1.5rem !important;
            margin-top: 1.5rem !important;
          }
        }
        .modal-choclin{
          width:85%
        }
        
        @media (max-width: 1400px) {
          .modal-choclin{
            height:90%
          }
        }

        .round-button{
          min-width: 60px;
          max-width:120px;
          text-decoration: none;
          display: inline-block;
          outline: none;
          cursor: pointer;
          border-style: none;
          color: white;
          background-color: #CD7430;
          border-radius: 100%;
          overflow: none;
          text-align: center;
          padding:0;
        }
        .round-button:before {
          content:'';
          display:inline-block;;
          vertical-align:middle;
          padding-top:100%;
        }
        .span-closer {
          display:inline-block;
          vertical-align:middle;
          max-width:90%;
          font-size: 2rem;
        }
        .round-button:active{
          background-color: #CD7430;
        }
        

        .modal-link{
          cursor: pointer;
          color: inherit; /* blue colors for links too */
          text-decoration: inherit; /* no underline */
          
        }
        .modal-link:hover{
          color: #70946E;
          
        }
        a {
          color: inherit; /* blue colors for links too */
          text-decoration: inherit; /* no underline */
        }
        a:hover{
          color: #70946E;
        }
        #about_usMore{
          color: #0a58ca; /* blue colors for links too */
          text-decoration: inherit; /* no underline */
        }
        #about_usMore:hover{
          color: #fff;
        }

        .pattern-background{
          background: url(../img/patterns_geometric-03.png);;
  
          background-color: rgba(112, 148, 110, 0.9);
        }
        #partner-form{
          border: 1px solid rgb(112, 148, 110,0.9);
          
        }
        @media (min-width:900px) {
          .modal-image{
            width:60%;
          }
        }
        @media (max-width:900px) {
          .modal-image{
            width:95%;
          }
          .frame {
            height: 100%; /* Can be anything */
            width: 100%; /* Can be anything */
           
        }
        
            #imagepreview{
    
              max-height: 100%;
            max-width: 100%;
            width: auto;
            height: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            }
        }
        

        .h3-like {
          display: block;
          font-size: 1.17em;
          margin-top: 1em;
          margin-bottom: 1em;
          margin-left: 0;
          margin-right: 0;
          font-weight: bold;
        }
        
        %base-star {
          display: inline-block; 
          
          &:after, &:before {
            font-size: 2em;
            color: #FFC600;
          }
        };
        
        %full-star {
          @extend %base-star;
          
          &:after {
            content: "\2605";
          }
        };
        
        %half-star {
          @extend %base-star;
          
          &:before {
            position: absolute;
            content: "\2606"; 
          }
          &:after {
            position: absolute;
            content: "\2605"; 
            background: linear-gradient(to right, #FFC600 50%,transparent 50%);
            background-clip: text;
            // Experimental: https://caniuse.com/mdn-css_properties_-webkit-text-fill-color
            -webkit-text-fill-color: transparent;
          }
        };
        %hover-star {
          @extend %base-star;
          
          &:after {
            content: "\2605"; 
            color: #d4a600 ;
          }
        };
        %empty-star {
          @extend %base-star;
          
          &:after {
            content: "\2606";
          }
        };
        
        // 5 Star Rating Score
        .rating-score {
          display: inline-flex;
          flex-direction: row;
          align-items: flex-start;
          margin: 0;
          padding: 0;
        
          > .rating-score-item {
            @extend %base-star;
            @extend %empty-star;
          }
        
          @for $i from 1 through 5 {
            &[data-rating='#{$i}'] {
              > .rating-score-item:nth-child(-n + #{$i}) {
                @extend %full-star;
              }
            }
          }
          
          @for $i from 0 through 4 {
            &[data-rating='#{$i + 0.5}'] {
              > .rating-score-item:nth-child(-n + #{$i}) {
                @extend %full-star;
              }
        
              > .rating-score-item:nth-child(#{$i + 1}) {
                @extend %half-star;
              }
            }
          }
        }
        
        // 5 Star Rating Form Field
        .rating-input {
          border: none;
          display: inline-flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
          margin: 0;
          padding: 0;
        
          > input {
            display: none;
          }
        
          > label {
            @extend %base-star;
            @extend %empty-star;
          }
        
          // Selected star color
          > input:checked ~ label {
            @extend %full-star;
          }
        
          // On hover color all stars grey by default
          &:hover > input + label {
            @extend %empty-star;
          }
        
          // Hover star color
          &:hover > input + label:hover,
          &:hover > input + label:hover ~ input + label {
            @extend %hover-star;
          }
        }

        @media (min-width: 0) {
          .g-mr-15 {
              margin-right: 1.07143rem !important;
          }
      }
      @media (min-width: 0){
          .g-mt-3 {
              margin-top: 0.21429rem !important;
          }
      }
      
      .g-height-50 {
          height: 50px;
      }
      
      .g-width-50 {
          width: 50px !important;
      }
      
      @media (min-width: 0){
          .g-pa-30 {
              padding: 2.14286rem !important;
          }
      }
      
      .g-bg-secondary {
          background-color: #fafafa !important;
      }
      
      .u-shadow-v18 {
          box-shadow: 0 5px 10px -6px rgba(0, 0, 0, 0.15);
      }
      
      .g-color-gray-dark-v4 {
          color: #777 !important;
      }
      
      .g-font-size-12 {
          font-size: 0.85714rem !important;
      }
      
      .media-comment {
          margin-top:20px
      }

      .disabled-input {
        pointer-events: none !important;
       
      }
      $body-color: slategray;
      $body-text-size: 1.6rem;
      $break-color: rgb(68, 68, 68);
      $page-color: snow;
      $shadow-color: rgba(0, 0, 0, .15);
      $outline-color: rgba(0, 0, 0, .1);
      $text-color: lighten(black, 30%);
      .sb {
        float: left;
        margin-bottom: .5rem;
        position: relative;
        width: 100%;
      }
      .section-break-1 {
        background-color: transparent;
        border: 0;
        border-top: 3px double $break-color;
        height: 3px;
        margin: 2rem auto;
        width: 50%;
      
        &:before {
          background-color: $page-color;
          color: $break-color;
          content: '\22A1';
          font-size: 20px;
          left: 50%;
          letter-spacing: 0;
          line-height: 1;
          position: absolute;
          text-indent: -2px;
          text-rendering: geometricPrecision;
          transform: translate(-50%, -65%);
          width: 10px;
        }
      }
      .blue-hover:hover{
        color:#0a58ca;
      }